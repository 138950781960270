<template>
	<div id="userInfo" class="el-content">
		<a-form ref="form">
			<a-tabs v-model:activeKey="activeName" @change="changeTabs">
				<a-tab-pane key="1" tab="基本信息">
					<div class="kd-user-li">
						<div class="kd-user-li-title">头像</div>
						<div class="kd-user-li-head">
							<img class="kd-pic-url" v-if="info.pic_url" :src="info.pic_url" alt="" />
							<img class="kd-pic-url" v-else src="../../../assets/img/moren.png" alt="" />
						</div>

						<div class="kd-user-li-opration">
							<kd-img-select :custom="true" @change=" e => { info.pic_url = e; } " ></kd-img-select>
						</div>
					</div>
					<div class="kd-user-li">
						<div class="kd-user-li-title">用户</div>
						<div class="kd-user-li-head">
							<a-input v-if="update_type == 1" v-model:value="info.name"></a-input>
							<span v-else>{{ info.name }}</span>
						</div>
						<div class="kd-user-li-opration"><a-button @click="update_type = 1" type="link">修改</a-button></div>
					</div>

					<div class="kd-user-li">
						<div class="kd-user-li-title">手机号</div>
						<div class="kd-user-li-head">
							<a-input v-if="update_type == 2" v-model:value="info.mobile"></a-input>
							<span v-else>{{ info.mobile }}</span>
						</div>
						<div class="kd-user-li-opration"><a-button @click="update_type = 2" type="link">修改</a-button></div>
					</div>
					<div class="kd-user-li">
						<div class="kd-user-li-title">密码</div>
						<div class="kd-user-li-head"><span>******</span></div>
						<div class="kd-user-li-opration"><a-button @click="passwordDialog = true" type="link">修改</a-button></div>
					</div>
					<div class="kd-user-li">
						<div class="kd-user-li-title">可创建小程序个数</div>
						<div class="kd-user-li-head">
							<span>{{ info.mini_limit }}</span>
						</div>
					</div>
					<div class="kd-user-li">
						<div class="kd-user-li-title">邮箱</div>
						<div class="kd-user-li-head">
							<a-input v-if="update_type == 3" v-model:value="info.email"></a-input>
							<span v-else>{{ info.email }}</span>
						</div>
						<div class="kd-user-li-opration"><a-button @click="update_type = 3" type="link">修改</a-button></div>
					</div>
					<div class="kd-user-li">
						<div class="kd-user-li-title">最后登录时间</div>
						<div class="kd-user-li-head">
							<span>{{ info.login_time }}</span>
						</div>
					</div>
					<div class="kd-user-li">
						<div class="kd-user-li-title">最后登录IP</div>
						<div class="kd-user-li-head">
							<span>{{ info.last_login_ip }}</span>
						</div>
					</div>
					<div class="kd-user-li">
						<div class="kd-user-li-title">到期时间</div>
						<div class="kd-user-li-head">
							<span v-if="info.is_permanent == 1">永久</span>
							<span v-else>{{ info.validity_time }}</span>
						</div>
					</div>
					<a-form-item style="margin-left: 10%;">
						<a-space>
							<a-button type="primary" @click="saveUserInfo">保存</a-button>
							<a-button @click="$router.go(-1)">返回</a-button>
						</a-space>
					</a-form-item>
				</a-tab-pane>
				<a-tab-pane key="2" tab="分配角色" force-render>
					<a-form-item label="分配角色">
						<a-checkbox-group v-model:value="group_ids">
							<a-checkbox :value="item.id" v-for="(item, index) in role" :key="index">{{ item.title }}</a-checkbox>
						</a-checkbox-group>
					</a-form-item>
					<a-form-item>
						<a-space>
							<a-button type="primary" @click="saveUserRole">保存</a-button>
							<a-button @click="$router.go(-1)">返回</a-button>
						</a-space>
					</a-form-item>
				</a-tab-pane>
				<a-tab-pane key="3" tab="应用管理">
					<a-button type="primary" @click="show.app = true">添加应用</a-button>
					<div class="employee-info-my-app">
						<div class="eima-item" v-for="(item, index) in app.list" :key="index">
							<img :src="item.logo" alt="" />
							<div class="eimai-info">{{ item.program_name }}</div>
						</div>
					</div>
					<!-- <div class="pager">
						<a-pagination
							show-size-changer
							v-model:current="app.page"
							v-model:pageSize="app.limit"
							:total="app.count"
							@showSizeChange=" (p, e) => { getProgramList(app.page, e); } "
							@change="e => getProgramList(e, app.limit)"
						/>
					</div> -->
				</a-tab-pane>
			</a-tabs>
		</a-form>
		<a-modal v-model:visible="passwordDialog" title="修改密码" width="600px" @ok="savePassWord" @cancel="passwordDialog = false">
			<a-form ref="form" :model="passwordForm" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="新密码"><a-input v-model:value="passwordForm.password"></a-input></a-form-item>
				<a-form-item label="再次新密码"><a-input v-model:value="passwordForm.re_password"></a-input></a-form-item>
			</a-form>
		</a-modal>
		<a-modal v-model:visible="show.app" title="选择应用" width="1000px" @ok="sureCheck" @cancel="show.app = false">
			<div class="employee-info-container">
				<div
					class="eic-item "
					:class="checkProgramIds.includes(item.program_id) ? 'eic-active' : ''"
					v-for="(item, index) in checkApp.list"
					:key="index"
					@click="checkProgram(index)"
				>
					<img :src="item.logo" alt="" />
					<div class="eici-info">
						<div>{{ item.program_name }}</div>
						<div>用户：{{ item.user.name }}</div>
						<div>剩余天数：{{ item.end_days }}天</div>
						<div>
							状态：
							<span>{{ item.is_use == 1 ? '使用中' : '已过期' }}</span>
						</div>
					</div>
				</div>
				<div style="width: 300px;height: 1px;"></div>
			</div>
			<div class="pager">
				<a-pagination
					show-size-changer
					v-model:current="checkApp.page"
					v-model:pageSize="checkApp.limit"
					:total="checkApp.count"
					@showSizeChange=" (p, e) => { getProgramList(checkApp.page, e); } "
					@change="e => getProgramList(e, checkApp.limit)"
				/>
			</div>
		</a-modal>
	</div>
</template>
<script>
import { reactive, toRefs } from 'vue';
import { useRoute } from 'vue-router';
import authModel from '@/api/saas/auth';
import userModel from '@/api/user';
import programModel from '@/api/saas/app';
export default {
	setup(){
		const state = reactive({
			activeName: '1',
			user_id: 0,
			info: [],
			update_type: 0, //1名称 2电话 3邮箱
			group_ids: [],
			passwordDialog: false,
			passwordForm: {
				password: '',
				re_password: ''
			},
			role: [],
			app: {
				list: [],
				page: 1,
				limit: 10,
				count: 0
			},
			show: {
				app: false
			},
			checkApp: {
				list: [],
				page: 1,
				limit: 10,
				count: 10
			},
			checkProgramIds: []
		})

		const opt = useRoute().query
		state.user_id = opt.user_id || 0

		authModel.getRole(1,99,res=>state.role = res.list)

		userModel.getUserDetail(opt.user_id).then(res=>{
			state.info = res
			state.group_ids = res.access
		})

		function saveUserInfo(){
			let data = {
				pic_url: state.info.pic_url,
				name: state.info.name,
				mobile: state.info.mobile,
				email: state.info.email,
				user_id: state.info.user_id,
				type: 2,
				rank: state.info.rank,
				scene: 'edit'
			}

			authModel.addOrEditEmployee('add',data,()=>{})
		}

		function savePassWord(){
			let data = {
				user_id: state.info.user_id,
				scene: 'password',
				password: state.passwordForm.password,
				re_password: state.passwordForm.re_password,
				type: 2,
				rank: state.info.rank
			}
			authModel.addOrEditEmployee('password',data,()=>{
				state.passwordDialog = false
			})
		}

		function saveUserRole(){
			let { info, group_ids } = state, new_group_id = [];
			if (group_ids.length > 0 && state.role.length > 0) {
				state.role.forEach(item => {
					if (group_ids.includes(item.id)) {
						new_group_id.push(item.id);
					}
				})
			}

			authModel.allocationRole(info.user_id,new_group_id,()=>{})
		}

		function changeTabs(){
			if (state.activeName == 3) {
				getProgramList();
				getSelectProgram(1, 9);
			}
		}

		function getProgramList() {
			authModel.getEmployeeShop(state.user_id,res=>{
				state.app.list = res
			})
		}

		function getSelectProgram(page,limit){
			programModel.getProgramList(1,page,limit,null,res=>state.checkApp = {limit,...res})
		}

		function checkProgram(index) {
			let id = state.checkApp.list[index].program_id;
			if (state.checkProgramIds.includes(id)) {
				let _i = state.checkProgramIds.indexOf(id);
				state.checkProgramIds.splice(_i, 1);
			} else {
				state.checkProgramIds.push(id);
			}
		}

		function sureCheck(){
			authModel.setEmployeeShop(state.user_id,state.checkProgramIds,()=>{
				getProgramList()
				state.show.app = false
			})
		}

		return{
			...toRefs(state),
			changeTabs,
			getProgramList,
			saveUserRole,
			savePassWord,
			saveUserInfo,
			getSelectProgram,
			checkProgram,
			sureCheck
		}
	}
}
</script>

<style lang="scss">
.kd-user-li {
	width: 100%;
	height: 60px;
	line-height: 60px;
	display: flex;
	font-size: 14px;
	color: #868686;
	border-bottom: 1px solid #f1eded;

	&-title {
		width: 10%;
	}
	&-head {
		width: 50%;
	}
	&-opration {
		width: 40%;
		text-align: right;
		color: #0074d9;
		cursor: pointer;
	}
}
.kd-pic-url {
	width: 50px;
	height: 50px;
	border-radius: 50%;
}
.employee-info {
	.el-dialog {
		width: 960px;
		.el-dialog__body {
			padding: 10px 20px;
		}
	}

	&-container {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.eic-item {
			width: 300px;
			height: 100upx;
			border: 1px solid #f4f4f4;
			display: flex;
			margin: 10px 0;
			cursor: pointer;

			img {
				width: 100px;
				height: 100px;
				margin-right: 10px;
			}
			.eici-info {
				height: 25px;
				line-height: 25px;
			}
		}
		.eic-item:hover {
			border: 1px solid #177ce3;
			color: #0074d9;
		}
		.eic-active {
			border: 1px solid #177ce3;
			color: #0074d9;
		}
	}
}

//我的应用
.employee-info-my-app {
	width: 100%;
	display: flex;
	justify-content: flex-start;

	.eima-item {
		width: 150px;
		height: 200px;
		border: 1px solid #f4f4f4;
		margin: 10px 10px 10px 0;
		line-height: 30px;

		img {
			width: 150px;
			height: 150px;
			margin-right: 10px;
		}
		.eimai-info {
			font-size: 14px;
			width: 90%;
			text-align: center;
			margin: auto;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: normal;
		}
	}
}
</style>
